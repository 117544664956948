


import React, { Component } from 'react';
import styled from 'styled-components'


class Principal extends Component {
    render() {
        return (
            <Container id="productos">

          

                        <iframe title="videopres" src="https://www.youtube.com/embed/Xv6mryXEMyE?loop=1&amp;playlist=Xv6mryXEMyE&amp;mute=1&amp;controls=0&amp;showinfo=1&amp;autoplay=1&amp;autohide=0&amp;wmode=transparent&amp;frameborder=0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" id="video" style={{    width: "100vw", height: "100vh"}}></iframe>

            </Container>
        )
    }
}

const Container = styled.div`
width: 100vw;
height: 100vh;
 object-fit: cover;
text-align: center;
padding-top: 4rem;
background-color: #ddd;
background-size: cover;

`;



export default Principal;