import React, { Component } from 'react';
import { FaFacebook, FaTwitter,  FaEnvelope, FaInstagram,  FaWhatsapp } from 'react-icons/fa';
import RubberBand from 'react-reveal/RubberBand';
import Zoom from 'react-reveal/Zoom';
import Bounce  from 'react-reveal/Bounce';
import styled from 'styled-components'
import logoPrueba from '../media/logoGMfirma.png';



class Footer extends Component {
    render() {
        return (
            <Container id="footer">
            <Zoom duration={1500} left delay={0} cascade  ><h1 className="pb-4 tituloseccion">Contáctenos</h1></Zoom>

            <Iconos class="row">

                <div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5">
                    <ul class="list-unstyled text-center" style={{marginRight: '1.5rem'}}>

                    <Bounce duration={2000} right delay={1400}>
                        <li ><FaEnvelope /><a href="mailto:contacto@gmarca.uy" target="_blank" rel="noopener noreferrer"> contacto@gmarca.uy </a></li>
                    </Bounce>
                        <Bounce duration={2000} left delay={2200}>        
                        <li >
                            <a href="https://wa.me/59899239841?text=Me%20interesar%C3%ADa%20un%20presupuesto%20para%20una%20%20las%20tecnolog%C3%ADas%20que%20estan%20ofreciendo" target="_blank" rel="noopener noreferrer">
                            <FaWhatsapp />
                             +598 (0)99 239 841
                             </a>
                             </li>
                        </Bounce>    

                    </ul>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5">
                <RubberBand delay={5000}>
                    <ul class="list-unstyled list-inline social text-center" >
                        <li class="list-inline-item"><a href="https://www.facebook.com/gmarca.uy" target="_blank" rel="noopener noreferrer" ><FaFacebook /></a></li>
                        <li class="list-inline-item"><a href="https://twitter.com/gmarca77" target="_blank" rel="noopener noreferrer"><FaTwitter /></a></li>
                        <li class="list-inline-item"><a href="https://www.instagram.com/gmarca77" target="_blank" rel="noopener noreferrer"><FaInstagram /></a></li>
            
                    </ul>
                    </RubberBand>
                </div>
                
            </Iconos>
            <hr />
            <div class="row">
            <hr />
                <div class="col-xs-12 col-sm-12 col-md-12 text-center text-dark"><p><u><a href="http://gmarca.uy/"> 
                <LogoFijo src={logoPrueba} />
                 </a></u></p><p> Montevideo, Uruguay</p></div>
            </div>


            </Container>
        )
    }
}

const Container = styled.div`
width: 100vw;
height: 100vh;
 object-fit: cover;
text-align: center;
color:#2a4867;
padding-top: 7rem;
background-color: #eaebfb;

`;

const LogoFijo = styled.img`
@media (max-width: 800px) {
    max-width: 90px;
}
  width:90px;
  object-fit: cover;
  
`;

const Iconos = styled.p`

@media (max-width: 768px) {

    font-size: 22px;
    margin-left: auto;
    margin-right: auto;

  }

  font-size: 28px;
  margin-left: auto;
  margin-right: auto;

font-size: 2rem;
margin-right:1rem;
`;



export default Footer;