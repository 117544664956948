import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components'
import {  Col } from 'reactstrap';

import LogoPrueba from '../media/logoGMfirma.png';

class Principal extends Component {
    render() {
        return (
            <Container id="quienes">

                <div>

                    <Fade delay={1100} left >
                        <LogoContent>
                            <Logo src={LogoPrueba} />
                        </LogoContent>
                        <Content>

                            <Col sm="12">
                                <TextoOculto>
                                    Somos una empresa especializada en tecnologías de la información. <br /> Contamos con profesionales capacitados y con amplia experiencia en servicios informáticos, VideoVigilancia, Control de Acceso, Networking, Desarrollo de Software, Telefonía IP, Internet de las cosas(IoT) entre otros.  <br />Nuestra prioridad es ofrecer a cada cliente una atención personalizada, ofreciéndole la solución perfecta para sus necesidades.
                            </TextoOculto>
                            <TextoVisible>
                             Somos una empresa joven con más de 15 años de trayectoria dedicada a desarrollar e implementar soluciones y productos tecnológicos. Siempre acompañando de la mano a nuestros clientes y proveedores. <br /><br />

 Como innovadores en el área de las tecnologías de la información, ofrecemos soluciones que se
caracterizan por su versatilidad, calidad y seguridad las cuales permiten a nuestros clientes mejorar procesos
fundamentales para su negocio.
<br /><br />

 Contamos con las certificaciones correspondientes de las marcas que mayormente utilizamos como Mikrotik, Ubiquiti, Grandstream, Hikvision, Siemon, Intelbras, Furukawa, Digifort, Nexxt. Las cuales avalan el compromiso que tenemos a la hora de implementar soluciones con pasos firmes y absoluta garantía.

                            </TextoVisible>
                            </Col>
                        </Content>
                    </Fade>
                </div>
            </Container>
        )
    }
}

const Container = styled.div`

@media (max-width: 800px) {
    padding: 7rem 1rem;
  }
width: 100vw;
height: 100vh;
 object-fit: cover;
text-align: center;
padding: 7rem 5rem;
background-color: #ddd;
background-size: cover;
`;

const Content = styled.div`

    margin: auto;

`;
const LogoContent = styled.div`
    padding: 0px 10px 20px 10px;
    
    margin: auto;
    max-width: 80vw;

`;

const TextoVisible = styled.p`

@media (max-width: 800px) {
    font-size: 14px;
    padding:2px;
  }

font-size: 15px;
color: #212529;

`;
const TextoOculto = styled.p`

@media (max-width: 800px) {
    font-size: 16px;
    padding:4px;
  }

font-size: 20px;
color: #212529;
display:none;

`;

const Logo = styled.img`
@media (max-width: 800px) {
    max-width: 60%;
}
  max-width:400px;
  image-fit: cover;

`;

export default Principal;