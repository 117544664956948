import NavBar from '../components/NavBar'
import Inicio from './Inicio'
import Quienes from './QuienesSomos'
import Servicios from './Servicios'
import Productos from './Productos'
import Soluciones from './Soluciones'
import Clientes from './Clientes'
import Contacto from './Contacto'

import React from 'react';


function Nav() {
  return (
    <>  
    <NavBar />
    <Inicio />
    <Quienes />
    <Servicios />
    <Productos />
    <Soluciones />
    <Clientes />
    <Contacto />
    
     </> 

  );
}

export default Nav;
