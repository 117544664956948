import React, { Component } from 'react';
import Slide from 'react-reveal/Slide';
import styled from 'styled-components'
import back from "./../media/compu.jpg";
import hikvision from '../media/hikvision.png';
import ubiquiti from '../media/ubiquiti.png';
import mikrotik from '../media/mikrotik.png';
import audiocodes from '../media/audiocodes.png';
import grandstream from '../media/grandstream.png';
import dahua from '../media/dahua.png';
import zkteco from '../media/zkteco.png';
import tplink from '../media/tplink.png';
import sonoff from '../media/sonoff.png';
import arduino from '../media/arduino.png';
import tiandy from '../media/tiandy.png';
import nexxt from '../media/nexxt.png';



const data = [
    {
        id:1, 
        title: "VIDEOVIGILANCIA - CCTV", 
        description: `Instalación, configuración y puesta en marcha de sistemas de videovigilacia, utilizando las últimas tecnologías del mercado. Controle su residencia o empresa desde donde esté. Contamos con una gran variedad de soluciones de CCTV para buscar una a su medida. `,
        src: hikvision
    
    },

    {
        id:2, 
        title: "TELEFONÍA IP", 
        description: `Implementación de tecnología IP para telefonía, con crecimiento exponencial. Diseñamos soluciones de telefonía a la medida de cada cliente, derribando cualquier barrera de comunicación`,
        src: ubiquiti
    },

    {
        id:3, 
        title: "NETWORKING | WIFI PRO", 
        description: ` Optimización, monitoreo e implementación de tecnologías para el funcionamiento optimo de la red de datos. Wifi profesional. Soluciones WIFI a medida para el hogar, empresas, depósitos, hoteles, campings. Roaming y balanceo de clientes`,
        src: grandstream
    },
    {
        id:4, 
        title: "DISEÑO WEB", 
        description: ` Servicio de diseño, modelado y mantenimiento de paginas web.          `,
        src: mikrotik
    },
    {
        id:5, 
        title: "SERVICIOS IT", 
        description: ` Todos los servicios informaticos y de administracion para empresas.                `,
        src: audiocodes
    }
    ,
    {
        id:6, 
        title: "CONTROL DE ACCESO", 
        description: ` Identifique y controle el acceso de personas a zonas importantes de manera segura y confiable.  `,
        src: dahua
    }
    ,
    {
        id:7, 
        title: "CABLEADO ESTRUCTURADO", 
        description: ` Obtenga un óptimo y eficaz uso de su red instalando servicios de teleconumicaciones y datos, tanto en categoría 5 como en categoría 6  `,
        src: zkteco
    }
    ,
    {
        id:8, 
        title: "RELOJES DE PERSONAL", 
        description: ` Controle de forma sencilla y efectiva, los tiempos de entrada y salida del personal, así como las horas extras, llegadas tarde, etc `,
        src: tplink
    }
    ,
    {
        id:9, 
        title: "VIDEOPORTEROS", 
        description: `  Utilice videoporteros para la identificación de personas que desean ingresar a su propiedad. `,
        src:sonoff
    }
    ,
    {
        id:10, 
        title: "SISTEMAS DE ALARMA", 
        description: `  Detectamos a tiempo cualquier amenaza de intrusos mediante distintas soluciones (inalámbricas, perimetrales, de interior y exterior). `,
        src: tiandy
    }
    ,
    {
        id:11, 
        title: "SISTEMAS DE ALARMA", 
        description: `  Detectamos a tiempo cualquier amenaza de intrusos mediante distintas soluciones (inalámbricas, perimetrales, de interior y exterior). `,
        src: arduino
    }
    ,
    {
        id:12, 
        title: "SISTEMAS DE ALARMA", 
        description: `  Detectamos a tiempo cualquier amenaza de intrusos mediante distintas soluciones (inalámbricas, perimetrales, de interior y exterior). `,
        src: nexxt
    }
]



class Principal extends Component {
    render() {
        return (
            <ContainerServicios id="soluciones">
            <Slide duration={1500} top cascade  ><Titulo>Marcas comercializadas</Titulo></Slide>
                <Content>
          
            <List>
              {data.map(solucion => {
                  if(solucion.id%2===1)
                    return (
                        <Slide duration={1500} left   >
                <Item
                 
                  key={solucion.id}
                  
                >
                <Image src={solucion.src} />
               
                </Item>
                </Slide>)
                  else
                    return (
                        <Slide duration={1500} right   >
                        <Item
                 
                 key={solucion.id}
                 
               >
               <Image src={solucion.src} />
              
               </Item>
                </Slide>)  

                
              }
              )}
            </List>
                       

            </Content>
            
            </ContainerServicios>

        )
    }
}

const ContainerServicios = styled.div`
@media (max-width: 800px) {
    padding: 7rem 1rem;
    
  }
  width: 100vw;
  height: 100vh;

 object-fit: cover;
text-align: center;
padding-top: 7rem;


background-image: url('${back}');
background-color: #ddd;
background-size: cover;

`;

const Content = styled.div`

    
`;

const List = styled.div`


@media (max-width: 1200px) {
  display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 30px 30px;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }
  @media (max-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 30px 30px;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }  
  @media (max-width: 760px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 30px 30px;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  padding: 30px 80px;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
`;

const Item = styled.div`
max-width: 150px;
max-height: 150px;
    border-radius: 100px;
    background: #ffffffc9;
`;

const Image = styled.img`


    object-fit: cover;
    border-radius: 40px;
    max-height: 150px;

`;


const Titulo = styled.p`
    color: white;
    font-weight: bolder;
  font-size: 26px;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
`;


export default Principal;