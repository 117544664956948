import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components'
import { Col } from 'reactstrap';
import back from "./../media/background_principal.jpg";

class Principal extends Component {
    render() {
        return (
            <Container id="principal">

                <div>

                    <Fade delay={800} left >
                        <Content>
                        <Col sm="12">
                            <Texto>Tecnología, servicio y profesionalismo <br />
                                    Todo en un mismo lugar. <br />
                                    Soluciones para su negocio o empresa

                            </Texto>
                        </Col>
                        </Content>
                    </Fade>
                </div>
            </Container>
        )
    }
}

const Container = styled.div`
width: 100vw;
height: 100vh;
 object-fit: cover;
text-align: center;
padding-top: 13rem;
background-image: url('${back}');
background-size: cover;
`;

const Content = styled.div`

@media (max-width: 800px) {
    
    padding: 20px 10px 20px 10px;
  }
    padding: 20px 10px 20px 10px;
    background-color: #00000085;
    margin: auto;
    max-width: 550px;
    border-radius: 10px;
`;

const Texto = styled.p`

@media (max-width: 800px) {
    font-size: 16px;
    padding:20px;
  }

font-size: 28px;
color:#e9ecef;
font-style: oblique;
`;



export default Principal;