


import React, { Component } from 'react';
import Slide from 'react-reveal/Slide';
import styled from 'styled-components'
import back from "./../media/servicios.png";
import { FaVideo,  FaNetworkWired,  FaPhoneAlt , FaSitemap,  FaCogs, FaEye, FaBell, FaCode,FaIdCard, FaCalendar } from 'react-icons/fa';


const data = [
    {
        id:1, 
        title: "VIDEOVIGILANCIA - CCTV", 
        description: `Instalación, configuración y puesta en marcha de sistemas de videovigilacia, utilizando las últimas tecnologías del mercado. Controle su residencia o empresa desde donde esté. Contamos con una gran variedad de soluciones de CCTV para buscar una a su medida. `,
        icon: <FaVideo />
    
    },

    {
        id:2, 
        title: "TELEFONÍA IP", 
        description: `Implementación de tecnología IP para telefonía, con crecimiento exponencial. Diseñamos soluciones de telefonía a la medida de cada cliente, derribando cualquier barrera de comunicación`,
        icon: <FaPhoneAlt />
    },

    {
        id:3, 
        title: "NETWORKING | WIFI PRO", 
        description: ` Optimización, monitoreo e implementación de tecnologías para el funcionamiento optimo de la red de datos. Wifi profesional. Soluciones WIFI a medida para el hogar, empresas, depósitos, hoteles, campings. Roaming y balanceo de clientes`,
        icon: <FaNetworkWired />
    },
    {
        id:4, 
        title: "DISEÑO WEB", 
        description: ` Servicio de diseño, modelado y mantenimiento de paginas web.          `,
        icon: <FaCode />
    },
    {
        id:5, 
        title: "SERVICIOS IT", 
        description: ` Todos los servicios informaticos y de administracion para empresas.                `,
        icon: <FaCogs />
    }
    ,
    {
        id:6, 
        title: "CONTROL DE ACCESO", 
        description: ` Identifique y controle el acceso de personas a zonas importantes de manera segura y confiable.  `,
        icon: <FaIdCard />
    }
    ,
    {
        id:7, 
        title: "CABLEADO ESTRUCTURADO", 
        description: ` Obtenga un óptimo y eficaz uso de su red instalando servicios de teleconumicaciones y datos, tanto en categoría 5 como en categoría 6  `,
        icon: <FaSitemap />
    }
    ,
    {
        id:8, 
        title: "RELOJES DE PERSONAL", 
        description: ` Controle de forma sencilla y efectiva, los tiempos de entrada y salida del personal, así como las horas extras, llegadas tarde, etc `,
        icon: <FaCalendar />
    }
    ,
    {
        id:9, 
        title: "VIDEOPORTEROS", 
        description: `  Utilice videoporteros para la identificación de personas que desean ingresar a su propiedad. `,
        icon: <FaEye />
    }
    ,
    {
        id:10, 
        title: "SISTEMAS DE ALARMA", 
        description: `  Detectamos a tiempo cualquier amenaza de intrusos mediante distintas soluciones (inalámbricas, perimetrales, de interior y exterior). `,
        icon: <FaBell />
    }
]



class Principal extends Component {
    render() {
        return (
            <ContainerServicios id="servicios">
            <Slide duration={1500} top cascade  ><Titulo>Nuestros Servicios</Titulo></Slide>
                <Content>
          
            <List>
              {data.map(servicio => {
                  if(servicio.id%2===1)
                    return (
                        <Slide duration={1500} left   >
                <Item
                 
                  key={servicio.id}
                  
                >
                {servicio.icon}
                <Name>{servicio.title}</Name>
                  
                  <Description>{servicio.description}</Description>
                </Item>
                </Slide>)
                  else
                    return (
                        <Slide duration={1500} right   >
                <Item
                 
                  key={servicio.id}
                  
                >
                {servicio.icon}
                <Name>{servicio.title}</Name>
                  
                  <Description>{servicio.description}</Description>
                </Item>
                </Slide>)  

                
              }
              )}
            </List>
                       

            </Content>
            
            </ContainerServicios>

        )
    }
}

const ContainerServicios = styled.div`
width: 100vw;
 object-fit: cover;
text-align: center;
padding-top: 7rem;


background-image: url('${back}');
background-color: #ddd;
background-size: cover;

`;

const Content = styled.div`

    
`;

const List = styled.div`

@media (max-width: 800px) {
    grid-template-columns: 1fr  ;
    padding: 30px 30px;
  }
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 30px 80px;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
`;

const Item = styled.div`
width: 100%;
border-radius: 20px;
padding: 40px;
background: white;
`;



const Name = styled.p`
    color: #4f4f4f;
    font-weight: bolder;
  font-size: 16px;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
`;
const Titulo = styled.p`
    color: white;
    font-weight: bolder;
  font-size: 26px;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
`;
const Description = styled.p`
  color: black;
  font-size: 16px;
  text-align: center;
  width: 100%;
 
`;

export default Principal;