import React from 'react';
import './App.css';
import Web from './views/Principal'
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Web />
      

  );
}

export default App;
