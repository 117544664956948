import React, { useState } from 'react';
import styled from 'styled-components';
import logo from '../media/logo.png';
import logoPrueba from '../media/logoGMfirmaB.png';
import ScrollspyNav from "react-scrollspy-nav";

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarText
} from 'reactstrap';


const Example = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const verifyCollapseState = () => {
      if(isOpen) 
      setIsOpen(!isOpen);
  }

  return (
                <ScrollspyNav
                    scrollTargetIds={["principal", "quienes", "servicios","productos","soluciones","footer"]}
                    offset={0}
                    activeNavClass="is-active"
                    scrollDuration="1000"
                    headerBackground="true"
                >
    <div style={{width: "100vw"}}>
      <NavbarGG  fixed="top" dark expand="md">
        <NavbarBrand href="http://gmarca.uy"><Logo src={logo} /></NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
          


            <NavItem> 
              <NavLink href="#principal" onClick={verifyCollapseState}>INICIO</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#quienes" onClick={verifyCollapseState}>QUIENES SOMOS</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#servicios" onClick={verifyCollapseState}>SERVICIOS</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#productos" onClick={verifyCollapseState}>TECNOLOGÍA</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#soluciones" onClick={verifyCollapseState}>SOLUCIONES</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#clientes" onClick={verifyCollapseState}>CLIENTES</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#footer" onClick={verifyCollapseState} >CONTACTO</NavLink>
            </NavItem>

          </Nav>
          <NavbarText style={{paddingRight:20}}><LogoFijo src={logoPrueba} /> </NavbarText>
        </Collapse>
      </NavbarGG>
    </div>
            </ScrollspyNav>
       
  );
}

const Logo = styled.img`
@media (max-width: 800px) {
    max-width: 90px;
}
  width:70px;
  object-fit: cover;
  opacity: 0.8;
`;
const LogoFijo = styled.img`
@media (max-width: 800px) {
    max-width: 90px;
}
  width:90px;
  object-fit: cover;
  
`;

const NavbarGG = styled(Navbar)`
 background-color:#357da8;
 color: white;
 width: 100vw;
 
`;


export default Example;