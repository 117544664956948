import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import Zoom from 'react-reveal/Zoom';
import styled from "styled-components";
import { Col } from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import Casalinda from "../media/Clientes/1.png";
import CentroTapicero from "../media/Clientes/2.png";
import MLCalentitas from "../media/Clientes/3.png";
import Incuer from "../media/Clientes/4.png";
import Tecnobalizas from "../media/Clientes/5.png";
import AvJefe from "../media/Clientes/6.png";
import Safiro from "../media/Clientes/7.png";
import Sabores from "../media/Clientes/8.png";
import Encuentro from "../media/Clientes/9.png";
import CasaViva from "../media/Clientes/10.png";
import CasaJardin from "../media/Clientes/11.png";
import Propositos from "../media/Clientes/12.png";
import Balitex from "../media/Clientes/13.png";
import Pizzalibre from "../media/Clientes/14.png";
import ImagenVisual from "../media/Clientes/15.png";

class Principal extends Component {
  render() {
    return (
      <Container id="clientes">
        <div>
        <Zoom duration={1900} left delay={0} cascade  >
            <Content>
              <Col sm="12">
                <TextoVisible><br />CLIENTES QUE CONFÍAN EN NOSOTROS:<br /><br /><br /></TextoVisible>
                <Carousel
                  autoPlay={true}
                  showArrows={false}
                  showStatus={false}
                  infiniteLoop={true}
                  centerMode={true}
                  interval={1500}
                  showThumbs={false}
                  transitionTime={900}
                  showIndicators={false}
                  stopOnHover={false}
                  centerSlidePercentage={100}
                >
                  <div>
                    <Logo src={Casalinda} />
                   
                  </div>
                  <div>
                    <Logo src={MLCalentitas} />
                   
                  </div>
                  <div>
                    <Logo src={CentroTapicero} />
                   
                  </div>
                  <div>
                    <Logo src={Tecnobalizas} />
                   
                  </div>
                  <div>
                    <Logo src={Incuer} />
                   
                  </div>
                  <div>
                    <Logo src={AvJefe} />
                   
                  </div>
                  <div>
                    <Logo src={CasaJardin} />
                   
                  </div>
                  <div>
                    <Logo src={Safiro} />
                   
                  </div>
                  <div>
                    <Logo src={Propositos} />
                   
                  </div>
                  <div>
                    <Logo src={Sabores} />
                   
                  </div>
                  <div>
                    <Logo src={CasaViva} />
                   
                  </div>
                  <div>
                    <Logo src={Encuentro} />
                   
                  </div>
                  <div>
                    <Logo src={Pizzalibre} />
                   
                  </div>
                  <div>
                    <Logo src={Balitex} />
                   
                  </div>
                  <div>
                    <Logo src={ImagenVisual} />
                   
                  </div>
                </Carousel>
              </Col>
            </Content>
          </Zoom>
        </div>
      </Container>
    );
  }
}

const Container = styled.div`
  @media (max-width: 800px) {
    padding: 10rem 1rem;
   
  }
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  text-align: center;
  padding: 7rem 5rem;
  
  background-size: cover;
`;

const Content = styled.div`
  margin: auto;
`;
const LogoContent = styled.div`
  padding: 0px 10px 20px 10px;

  margin: auto;
  max-width: 80vw;
`;

const TextoVisible = styled.p`
  @media (max-width: 800px) {
    font-size: 20px;
    padding: 2px;
  }

  font-size: 35px;
  color: #212529;
`;
const TextoOculto = styled.p`
  @media (max-width: 800px) {
    font-size: 16px;
    padding: 4px;
  }

  font-size: 20px;
  color: #212529;
  display: none;
`;

const Logo = styled.img`
  @media (max-width: 800px) {
    max-width: 70%;
  }
  max-width: 150px;
  image-fit: cover;
  border-radius:150px;

  
`;

export default Principal;
